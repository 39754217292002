import methods from '@/enums/methods';
import Thoth from '../base/thoth';

class ComissionReportService extends Thoth {
    getAgents() {
        return this.fetch({
            url: '/report/comission/agents',
        });
    }

    getAgentsAndComission() {
        return this.fetch({
            url: '/report/comission/agents/comission',
        });
    }

    getAllComissionMode() {
        return this.fetch({
            url: '/report/comission/mode',
        });
    }

    generatePdf(params, body) {
        const { id, dateInitial, dateEnd } = params;
        const {
            type, note, date, value,
        } = body;

        return this.fetch({
            url: '/report/comission/create',
            method: methods.POST,
            data: {
                type, note, date, value,
            },
            params: { id, dateInitial, dateEnd },
        });
    }

    reportComissionSend({
        date, email,
    }) {
        return this.fetch({
            method: methods.POST,
            url: '/report/comission/send',
            data: { email },
            params: { date },
        });
    }

    reportDownload({ name }) {
        return this.fetch({
            url: '/report/comission/download',
            params: { name },
            responseType: 'blob',
        });
    }

    comissionCreateMode({
        comissionName,
        mdr,
        antecipationTax,
        antecipationType,
        machineTax,
    }) {
        return this.fetch({
            method: methods.POST,
            url: '/report/comission/create/mode',
            data: {
                comissionName,
                mdr,
                antecipationTax,
                antecipationType,
                machineTax,
            },
        });
    }

    comissionUpdateUser({
        id,
        percent_taxes,
        cost_help,
        discount,
        comission_mode_id,
    }) {
        return this.fetch({
            url: '/report/comission/update/agent/tax',
            data: {
                percent_taxes,
                cost_help,
                discount,
                comission_mode_id,
            },
            method: methods.PUT,
            params: { id },
        });
    }

    updateFundingTax({
        tax,
    }) {
        return this.fetch({
            method: methods.PUT,
            url: '/report/comission/update/tax',
            data: { tax },
        });
    }

    getHistory({
        id,
    }) {
        return this.fetch({
            url: '/report/comission/history/discounts',
            params: { id },
        });
    }

    getHistoryComission({
        id,
        dateEnd,
    }) {
        return this.fetch({
            url: '/report/comission/view',
            params: {
                id, dateEnd,
            },
        });
    }

    getReport({
        agentId,
        dateInitial,
        dateEnd,
    }) {
        return this.fetch({
            url: 'report/comission/show',
            params: {
                id: agentId,
                dateInitial,
                dateEnd,
                Apikey: process.env.VUE_APP_API_KEY_THOTH,
            },
        });
    }

    comissionUpdateMode({
        id,
        comissionName,
        mdr,
        antecipationTax,
        antecipationType,
        machineTax,
    }) {
        return this.fetch({
            method: methods.PUT,
            url: '/report/comission/mode/update',
            data: {

                comissionName,
                mdr,
                antecipationTax,
                antecipationType,
                machineTax,
            },
            params: { id },
        });
    }
}

export default new ComissionReportService();
