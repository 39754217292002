<template>
  <b-container>
    <b-card>
      <b-tabs>
        <b-tab>
          <template #title>
            <b-icon icon="file-earmark-text" />
            <span>Agentes</span>
          </template>

          <b-button
            variant="primary"
            class="d-block ml-auto mb-2"
            @click="() => {
              $bvModal.show('modal-funding');
            }"
          >
            Ajustar Taxa do Funding
          </b-button>
          <b-table
            class="text-center"
            :items="items"
            hover
            :fields="[
              {
                key: 'name',
                label: 'NOME'
              },
              {
                label: 'Taxa de Impostos',
                key: 'percentTax',
              },
              {
                label: 'Ajuda de Custo',
                key: 'costHelp',
              },
              {
                label: 'Taxa do Funding',
                key: 'fundingTax',
              },
              {
                label: 'Descrição da Comissão',
                key: 'comissionDescription',
              }
            ]"
            :per-page="10"
            :current-page="page"
            @row-clicked="handleRowClick"
          >
            <template #cell(percentTax)="data">
              {{ data.item.percentTax }}%
            </template>

            <template #cell(costHelp)="data">
              {{ data.item.costHelp | value }}
            </template>

            <template #cell(fundingTax)="data">
              {{ data.item.fundingTax }}%
            </template>

          </b-table>

          <b-pagination
            v-model="page"
            :total-rows="items.length"
            :per-page="10"
            align="center"
            size="sm"
            class="my-2"
          />

          </b-button></b-tab>

        <b-tab>
          <template #title>
            <b-icon icon="file-earmark-text" />
            <span>Perfis</span>
          </template>

          <profile
            :items="comissionMode"
            @refresh-items="getAllComissionMode"
          />
        </b-tab>
      </b-tabs>
    </b-card>

    <b-modal
      id="modal-agent"
      ref="liquidated-modal"
      size="lg"
      scrollable
      :title="newComission.name"
      hide-footer
    >
      <validation-observer
        #default="{ invalid }"
        ref="simpleRules"
      >

        <b-form>
          <b-form-group
            label-for="name"
            label="Nome"
          >
            <validation-provider
              #default="{ errors }"
              name="Nome"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="newComission.name"
                disabled
                :state="errors.length > 0 ? false:null"
                placeholder="Nome"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="mdr"
            label="Taxa de Impostos"
          >
            <validation-provider
              #default="{ errors }"
              name="Taxa de Impostos"
              rules="required|comission-percent"
            >
              <b-input-group append="%">
                <b-form-input
                  id="mdr"
                  v-model="newComission.percent_taxes"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Taxa de Impostos"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="antecipation_type"
            label="Perfil"
          >
            <b-form-select
              id="antecipation_type"
              v-model="newComission.comission_mode_id"
              :options="comissionModeOptions"
              placeholder="Perfil"
            />
          </b-form-group>

          <b-form-group
            label-for="antecipation_tax"
            label="Desconto"
          >
            <validation-provider
              #default="{ errors }"
              name="Desconto"
              rules="required"
            >

              <b-form-input
                id="antecipation_tax"
                v-model="newComission.discount"
                v-money="money"
                :state="errors.length > 0 ? false:null"
                placeholder="Desconto"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="antecipation_tax"
            label="Ajuda de Custo"
          >
            <validation-provider
              #default="{ errors }"
              name="Ajuda de Custo"
              rules="required"
            >

              <b-form-input
                id="antecipation_tax"
                v-model="newComission.cost_help"
                v-money="money"
                :state="errors.length > 0 ? false:null"
                placeholder="Ajuda de Custo"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            variant="primary"
            :disabled="invalid"
            @click="comissionUpdateUser"
          >
            Editar
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-funding"
      ref="liquidated-modal"
      size="lg"
      scrollable
      :title="newComission.name"
      hide-footer
    >
      <validation-observer
        #default="{ invalid }"
        ref="simpleRules"
      >

        <b-form>
          <b-form-group
            label-for="tax"
            label="Taxa do Funding"
          >
            <validation-provider
              #default="{ errors }"
              name="Taxa de Impostos"
              rules="required|comission-percent"
            >
              <b-input-group append="%">
                <b-form-input
                  id="tax"
                  v-model="fundingTax"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Taxa do Funding"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            variant="primary"
            :disabled="invalid"
            @click="updateFundingTax"
          >
            Editar
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-container>
</template>

<script>
import {
    BModal, BContainer, BCard, BTable, BPagination, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BInputGroup,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import Profile from './components/Profile.vue';

import ComissionReportService from '@/service/comission_reports';

export default {
    components: {
        BContainer,
        BCard,
        BTable,
        BPagination,
        BTabs,
        BTab,
        Profile,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BButton,
        BModal,
        BInputGroup,
    },

    data() {
        return {
            money: {
                decimal: '.',
                thousands: '',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
            items: [],
            comissionMode: [],
            page: 1,
            newComission: {},
            fundingTax: null,
        };
    },

    computed: {
        comissionModeOptions() {
            return this.comissionMode.map(item => ({
                text: item.comission_name,
                value: item.id,
            }));
        },
    },

    async mounted() {
        this.callLoading(true);
        await Promise.all([
            this.getAgentsAndComission(),
            this.getAllComissionMode(),
        ]);
        this.callLoading(false);
    },

    methods: {
        handleRowClick(e) {
            this.newComission = {
                id: e.id,
                name: e.name,
                comission_mode_id: e.comissionModeId,
                comissionDescription: e.comissionDescription,
                percent_taxes: e.percentTax.replace('-', '').trim(),
                cost_help: `R$ ${e.costHelp}`,
                discount: `R$ ${e.discount}`,
            };
            this.$bvModal.show('modal-agent');
        },

        async getAgentsAndComission() {
            const response = await ComissionReportService.getAgentsAndComission();

            this.items = response.data;

            this.fundingTax = this.items[0].fundingTax || 0;
        },

        error(message) {
            this.$swal({
                icon: 'error',
                text: message,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },
        success(message) {
            this.$swal({
                icon: 'success',
                text: message,
                customClass: {
                    confirmButton: 'btn btn-primary btn-modal',
                },
                buttonsStyling: false,
            });
        },

        async getAllComissionMode() {
            const { data } = await ComissionReportService.getAllComissionMode();

            this.comissionMode = data;
        },

        async comissionUpdateUser() {
            const isConfirmedAction = await this.confirmAnAction('Deseja Editar o Agente?');

            if (!isConfirmedAction) return;
            const { status } = await ComissionReportService.comissionUpdateUser({
                ...this.newComission,
                comission_mode_id: +this.newComission.comission_mode_id,
                cost_help: this.newComission.cost_help.replace('R$ ', ''),
                discount: this.newComission.discount.replace('R$ ', ''),
            });

            if (status === 200) {
                this.success('Agente editado com sucesso');
            } else {
                this.error('Erro ao editar agente');
            }

            this.$bvModal.hide('modal-agent');
            this.getAgentsAndComission();
        },

        async updateFundingTax() {
            const isConfirmedAction = await this.confirmAnAction('Deseja atualizar a taxa do funding?');

            if (!isConfirmedAction) return;
            const { status } = await ComissionReportService.updateFundingTax({
                tax: this.fundingTax,
            });

            if (status === 200) {
                this.success('Taxa editada com sucesso');
            } else {
                this.error('Erro ao editar taxa');
            }

            this.$bvModal.hide('modal-funding');
            this.getAgentsAndComission();
        },
    },
};
</script>
