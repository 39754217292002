var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-card',[_c('b-tabs',[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('b-icon',{attrs:{"icon":"file-earmark-text"}}),_c('span',[_vm._v("Agentes")])]},proxy:true}])},[_c('b-button',{staticClass:"d-block ml-auto mb-2",attrs:{"variant":"primary"},on:{"click":function () {
            _vm.$bvModal.show('modal-funding');
          }}},[_vm._v(" Ajustar Taxa do Funding ")]),_c('b-table',{staticClass:"text-center",attrs:{"items":_vm.items,"hover":"","fields":[
            {
              key: 'name',
              label: 'NOME'
            },
            {
              label: 'Taxa de Impostos',
              key: 'percentTax',
            },
            {
              label: 'Ajuda de Custo',
              key: 'costHelp',
            },
            {
              label: 'Taxa do Funding',
              key: 'fundingTax',
            },
            {
              label: 'Descrição da Comissão',
              key: 'comissionDescription',
            }
          ],"per-page":10,"current-page":_vm.page},on:{"row-clicked":_vm.handleRowClick},scopedSlots:_vm._u([{key:"cell(percentTax)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.percentTax)+"% ")]}},{key:"cell(costHelp)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.costHelp))+" ")]}},{key:"cell(fundingTax)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.fundingTax)+"% ")]}}])}),_c('b-pagination',{staticClass:"my-2",attrs:{"total-rows":_vm.items.length,"per-page":10,"align":"center","size":"sm"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('b-icon',{attrs:{"icon":"file-earmark-text"}}),_c('span',[_vm._v("Perfis")])]},proxy:true}])},[_c('profile',{attrs:{"items":_vm.comissionMode},on:{"refresh-items":_vm.getAllComissionMode}})],1)],1)],1),_c('b-modal',{ref:"liquidated-modal",attrs:{"id":"modal-agent","size":"lg","scrollable":"","title":_vm.newComission.name,"hide-footer":""}},[_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('b-form',[_c('b-form-group',{attrs:{"label-for":"name","label":"Nome"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":"","state":errors.length > 0 ? false:null,"placeholder":"Nome"},model:{value:(_vm.newComission.name),callback:function ($$v) {_vm.$set(_vm.newComission, "name", $$v)},expression:"newComission.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"mdr","label":"Taxa de Impostos"}},[_c('validation-provider',{attrs:{"name":"Taxa de Impostos","rules":"required|comission-percent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"id":"mdr","state":errors.length > 0 ? false:null,"placeholder":"Taxa de Impostos"},model:{value:(_vm.newComission.percent_taxes),callback:function ($$v) {_vm.$set(_vm.newComission, "percent_taxes", $$v)},expression:"newComission.percent_taxes"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"antecipation_type","label":"Perfil"}},[_c('b-form-select',{attrs:{"id":"antecipation_type","options":_vm.comissionModeOptions,"placeholder":"Perfil"},model:{value:(_vm.newComission.comission_mode_id),callback:function ($$v) {_vm.$set(_vm.newComission, "comission_mode_id", $$v)},expression:"newComission.comission_mode_id"}})],1),_c('b-form-group',{attrs:{"label-for":"antecipation_tax","label":"Desconto"}},[_c('validation-provider',{attrs:{"name":"Desconto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"id":"antecipation_tax","state":errors.length > 0 ? false:null,"placeholder":"Desconto"},model:{value:(_vm.newComission.discount),callback:function ($$v) {_vm.$set(_vm.newComission, "discount", $$v)},expression:"newComission.discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"antecipation_tax","label":"Ajuda de Custo"}},[_c('validation-provider',{attrs:{"name":"Ajuda de Custo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"id":"antecipation_tax","state":errors.length > 0 ? false:null,"placeholder":"Ajuda de Custo"},model:{value:(_vm.newComission.cost_help),callback:function ($$v) {_vm.$set(_vm.newComission, "cost_help", $$v)},expression:"newComission.cost_help"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.comissionUpdateUser}},[_vm._v(" Editar ")])],1)]}}])})],1),_c('b-modal',{ref:"liquidated-modal",attrs:{"id":"modal-funding","size":"lg","scrollable":"","title":_vm.newComission.name,"hide-footer":""}},[_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('b-form',[_c('b-form-group',{attrs:{"label-for":"tax","label":"Taxa do Funding"}},[_c('validation-provider',{attrs:{"name":"Taxa de Impostos","rules":"required|comission-percent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"id":"tax","state":errors.length > 0 ? false:null,"placeholder":"Taxa do Funding"},model:{value:(_vm.fundingTax),callback:function ($$v) {_vm.fundingTax=$$v},expression:"fundingTax"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.updateFundingTax}},[_vm._v(" Editar ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }