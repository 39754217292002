<template>
  <div>
    <b-button
      variant="primary"
      class="d-block ml-auto mb-2"
      @click="() => {
        newComission = {
          antecipationType: null,
        }
        isEdit = false;
        $bvModal.show('modal-scrollable');
      }"
    >
      Criar Novo Perfil
    </b-button>
    <b-table
      class="text-center"
      :items="items"
      :per-page="10"
      hover
      :fields="[
        { key: 'comission_name', label: 'NOME'},
        { key: 'comission_description', label: 'DESCRIÇÃO'},
        { key: 'antecipation_type', label: 'Tipo de Antecipação'},
        { key: 'antecipation_tax', label: 'Taxa de Antecipação'},
        { key: 'machine_tax', label: 'Taxa do POS'},
      ]"
      :current-page="page"
      @row-clicked="handleRowClick"
    >
      <template #cell(name)="data">
        {{ data.item.name }}
      </template>

      <template #cell(antecipation_tax)="data">
        {{ data.item.antecipation_tax * 100 }}%
      </template>

      <template #cell(machine_tax)="data">
        {{ data.item.machine_tax | value }}
      </template>
    </b-table>

    <b-pagination
      v-model="page"
      :total-rows="items.length"
      :per-page="10"
      align="center"
      size="sm"
      class="my-1"
    />

    <b-modal
      id="modal-scrollable"
      ref="liquidated-modal"
      size="lg"
      scrollable
      title="Criar Novo Perfil"
      hide-footer
    >
      <validation-observer
        #default="{ invalid }"
        ref="simpleRules"
      >

        <b-form>
          <b-form-group
            label-for="name"
            label="Nome"
          >
            <validation-provider
              #default="{ errors }"
              name="Nome"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="newComission.comissionName"
                :state="errors.length > 0 ? false:null"
                placeholder="Nome"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="mdr"
            label="MDR"
          >
            <validation-provider
              #default="{ errors }"
              name="MDR"
              rules="required|comission-percent"
            >
              <b-input-group append="%">
                <b-form-input
                  id="mdr"
                  v-model="newComission.mdr"
                  :state="errors.length > 0 ? false:null"
                  placeholder="MDR"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="antecipation_type"
            label="Tipo da Antecipação"
          >
            <b-form-select
              id="antecipation_type"
              v-model="newComission.antecipationType"
              :options="[
                { text: 'Seleciona um Tipo de Antecipação', value: null },
                { text: 'Liquida', value: 'liquido' },
                { text: 'Bruta', value: 'bruto' }
              ]"
              placeholder="Tipo da Antecipação"
            />
          </b-form-group>

          <b-form-group
            label-for="antecipation_tax"
            label="Taxa de Antecipação"
          >
            <validation-provider
              #default="{ errors }"
              name="Taxa de Antecipação"
              rules="required|comission-percent"
            >
              <b-input-group append="%">
                <b-form-input
                  id="antecipation_tax"
                  v-model="newComission.antecipationTax"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Taxa de Antecipação"
                />

              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="antecipation_tax"
            label="Taxa do POS"
          >
            <validation-provider
              #default="{ errors }"
              name="Taxa do POS"
              rules="required"
            >

              <b-form-input
                id="antecipation_tax"
                v-model="newComission.machineTax"
                v-money="money"
                :state="errors.length > 0 ? false:null"
                placeholder="Taxa do POS"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            variant="primary"
            :disabled="invalid"
            @click="comissionCreateMode"
          >
            {{ isEdit ? 'Editar' : 'Criar' }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
    BTable, BPagination, BModal, BForm, BFormGroup, BFormInput, BButton, BFormSelect, BInputGroup,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ComissionReportService from '@/service/comission_reports';

import '@validations';

export default {
    components: {
        BTable,
        BPagination,
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BFormSelect,
        BInputGroup,
        ValidationProvider,
        ValidationObserver,
    },

    props: {
        items: {
            default: () => [],
            type: Array,
        },
    },

    data() {
        return {
            money: {
                decimal: '.',
                thousands: '',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
            newComission: {
                antecipationType: null,
            },
            isEdit: false,
            page: 1,
        };
    },

    methods: {
        handleRowClick({
            id,
            comission_name: comissionName,
            mdr_tax: mdr,
            machine_tax: machineTax,
            antecipation_tax: antecipationTax,
            antecipation_type: antecipationType,
        }) {
            this.newComission = {
                id,
                comissionName,
                mdr: mdr * 100,
                machineTax,
                antecipationTax: antecipationTax * 100,
                antecipationType: antecipationType.split('Valor')[1].trim(),
            };

            this.isEdit = true;
            this.$bvModal.show('modal-scrollable');
        },

        async callModal() {
            return this.$bvModal
                .msgBoxConfirm(this.isEdit ? 'Deseja editar o perfil' : 'Deseja criar o perfil?', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                });
        },

        error(message) {
            this.$swal({
                icon: 'error',
                text: message,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },
        success(message) {
            this.$swal({
                icon: 'success',
                text: message,
                customClass: {
                    confirmButton: 'btn btn-primary btn-modal',
                },
                buttonsStyling: false,
            });
        },

        async comissionCreateMode() {
            const isConfirmedAction = await this.callModal();
            if (!isConfirmedAction) return;

            const request = {
                ...this.newComission,
                mdr: +this.newComission.mdr,
                antecipationTax: +this.newComission.antecipationTax,
                machineTax: +this.newComission.machineTax.replace(/[R$]/g, '').replace(',', '.'),
            };

            const { status } = this.isEdit ? await ComissionReportService.comissionUpdateMode(request) : await ComissionReportService.comissionCreateMode(request);

            if (status === 200) {
                this.success(this.isEdit ? 'Perfil editado com sucesso' : 'Perfil Criado com Sucesso');
            } else {
                this.error(this.ieEdit ? 'Erro ao editar perfil' : 'Erro ao criar perfil');
            }

            this.$emit('refresh-items');
        },
    },
};
</script>
